import { log } from ":mods";
import { EventViewProps } from "../model";
import { Show } from "solid-js";

export function Event({
  event_id,
  title,
  event_details,
  onEdit,
  onDelete,
  onOpen,
  disable_edit,
  disable_delete,
  ...props
}: EventViewProps) {
  const handleOnEdit = () => {
    log.dev.debug("this event wants edit: ", event_id);
    onEdit?.(event_id);
  };
  const handleOnDelete = () => {
    log.dev.debug("this event wants delete: ", event_id);
    onDelete?.(event_id);
  };
  const handleOpenEvent = () => {
    log.dev.debug("this event wants to be opened: ", event_id);
    onOpen?.(event_id);
  };
  return (
    <div>
      <hr class="w-full h-1px bg-[#BDD2EA] mt-2.5 opacity-60" />
      <div class="p-2.5" {...props}>
        <div id="content-container" class="space-y-2">
          <p id="event-type" class="text-16px">
            {event_details.type}
          </p>

          <div id="row-container" class="flex justify-between items-center">
            <div id="title-container" class="flex gap-x-2.5 items-center">
              <p class="text-28px font-bold">{title}</p>
              <Show when={!disable_edit}>
                <p class="text-16px underline-offset-2 underline cursor-pointer" onClick={handleOnEdit}>
                  Edit
                </p>
              </Show>
            </div>
            <Show when={!disable_delete}>
              <p class="text-16px underline-offset-2 underline cursor-pointer" onClick={handleOnDelete}>
                Delete
              </p>
            </Show>
          </div>

          <p id="event-datetime" class="text#highlight text-16px">
            {" "}
            {event_details.date} - {event_details.time} |{" "}
            <span class="underline underline-offset-2 cursor-pointer" onClick={handleOpenEvent}>
              {" "}
              {event_details.action + ">"}
            </span>{" "}
          </p>
          <p id="event-description" class="text-16px w-80%">
            {event_details.description}
          </p>
        </div>
      </div>
    </div>
  );
}
